import './App.scss';

import React, { useState } from 'react';
import Lottie from 'lottie-react-web'
import music from './music';
import info from './info';
import ig from './ig';
import knife from './knife';

const App = props => {
  const [navOpen, setNavOpen] = useState(false);
  const [inAnimation, setInAnimation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);

  const menuItemMap = [
    document.getElementById("navigation-item-1"),
    document.getElementById("navigation-item-2"),
    document.getElementById("navigation-item-3"),
    document.getElementById("navigation-item-4")
  ];

  const openNavigationMenu = () => {
    const logo = document.getElementById("navigation-logo");

    setSelectedItem(-1);
    if (!inAnimation) {
      if (navOpen) {
        logo.classList.remove('text-pop-up-top-reverse');
        menuItemMap.forEach(item => {
          item.lastElementChild.classList.remove('flip-in-hor-bottom');
          item.firstElementChild.classList.remove('fade-in');
        });
      } else {
        logo.classList.remove('text-pop-up-top');
        menuItemMap.forEach(item => {
          item.lastElementChild.classList.remove('flip-in-hor-bottom-reverse')
        });
      }

      // workaround to restart animations
      void logo.offsetWidth;

      if (navOpen) {
        logo.classList.add('text-pop-up-top');
        menuItemMap.forEach(item => {
          item.lastElementChild.classList.add('flip-in-hor-bottom-reverse')
        });
      } else {
        logo.classList.add('text-pop-up-top-reverse');
        menuItemMap.forEach(item => {
          item.lastElementChild.classList.add('flip-in-hor-bottom')
        });
      }

      setNavOpen(!navOpen);
    }
  };

  const handleMenuItemClick = index => () => {
    if (selectedItem === index) {
      setSelectedItem(-1);

      menuItemMap[index].firstElementChild.classList.remove('fade-in'); void menuItemMap[index].offsetWidth;
      menuItemMap[index].firstElementChild.classList.add('fade-out');
    } else {
      if (selectedItem !== -1) {
        menuItemMap[selectedItem].firstElementChild.classList.remove('fade-in');
      }
      setSelectedItem(index);

      menuItemMap[index].firstElementChild.classList.remove('fade-out'); void menuItemMap[index].offsetWidth;
      menuItemMap[index].firstElementChild.classList.add('fade-in');
    }
  };

  return (
    <div>
      <header className="App-header">
        <video autoPlay muted>
          <source src="landing-page-smoke.mp4" type="video/mp4" />
        </video>
      </header>
      <main className="App-main">
        <div className="App-navigation">
          <h1 id="navigation-logo"
              onClick={openNavigationMenu}
              onAnimationStart={() => setInAnimation(true)}
              onAnimationEnd={() => setInAnimation(false)}
              className="text-pop-up-top no-select">
              D
          </h1>
          <div id="navigation-item-1" style={{ position: 'relative' }}>
            <div className="navigation-item-container">
              <h4>Who I is</h4>
              <h4 style={{ left: '220px' }}>></h4>
            </div>
            <div className="item-1 no-select" onClick={handleMenuItemClick(0)}>
              <Lottie options={{ animationData: info }} width="40px" speed={selectedItem === 0 ? 1 : 0} />
            </div>
          </div>
          <div id="navigation-item-2" style={{ position: 'relative' }}>
            <div className="navigation-item-container">
              <h4>Music</h4>
              <h4 style={{ left: '220px' }}>></h4>
            </div>
            <div className="item-2 no-select" onClick={handleMenuItemClick(1)}>
              <Lottie options={{ animationData: music }} width="40px" speed={selectedItem === 1 ? 1 : 0} />
            </div>
          </div>
          <div id="navigation-item-3" style={{ position: 'relative' }}>
            <div className="navigation-item-container">
              <h4>Projects</h4>
              <h4 style={{ left: '220px' }}>></h4>
            </div>
            <div className="item-3 no-select" onClick={handleMenuItemClick(2)}>
              <Lottie options={{ animationData: knife }} width="40px" speed={selectedItem === 2 ? 1 : 0} />
            </div>
          </div>
          <div id="navigation-item-4" style={{ position: 'relative' }}>
            <div className="navigation-item-container">
              <h4>__dipi</h4>
              <h4 style={{ left: '220px' }}>></h4>
            </div>
            <div className="item-4 no-select" onClick={handleMenuItemClick(3)}>
              <Lottie options={{ animationData: ig }} width="40px" speed={selectedItem === 3 ? 1 : 0} />
            </div>
          </div>
        </div>
        <div className="App-version">
          <h5 style={{ color: 'white' }}>Site Version 0.0.1</h5>
        </div>
      </main>
    </div>
  );
};

export default App;
